<template>
    <div class="welfare">
        <div class="welfarehead">
            <div class="welfare-title">
                离活动结束：
                <span>{{ ttian }}</span>日
                <span>{{ tshi }}</span>时
                <span>{{ tfen }}</span>分
            </div>
            <div class="welfare-content">
                <div class="welfare-content-table">
                    <div style="background:#ffffff;" v-for="(item,index) in taskGroups" :key="index" @click="golist(item,index)">
                        <img v-if="!item.locked && indexs == index" class="tab-day" :src="item.selectedNameIcon" alt="">
                        <div v-if="!item.locked">{{item.name}}</div>
                        <div v-if="item.locked"><img src="https://ppyos.xijiuyou.com/202303/lock-icon.png" alt="">{{item.name}}</div>
                    </div>
                    <!-- <div>

                    </div>
                    <div>
                        <div><img src="https://ppyos.xijiuyou.com/202303/lock-icon.png" alt="">第三天</div>
                    </div> -->
                </div>
                <div class="welfare-content-content">
                    <div class="welfare-content-content-title">{{ taskInfo.waitingDone>0?'再完成': (taskInfo.awardButton===undefined ?'':'任务已全部完成') }}  <span v-if="taskInfo.waitingDone>0">{{ taskInfo.waitingDone }}</span> {{taskInfo.waitingDone>0?'个任务可领取每日礼包':''}}</div>
                    <div class="welfare-content-list" v-if="taskInfo.subTasks" :style="indexs>=2?'margin-bottom:41px;':''">
                        <div v-for="(item,index) in taskInfo.subTasks" :class="item.isAutoAwardTask?'welfare-content-caibei':''" :key="index" :style="taskInfo.subTasks.length>2?item.done?'opacity: 0.4;':'':item.done?'width:49%;opacity: 0.4;':'width:49%;'" @click="golink(item,index)">
                            <template v-if="!item.isAutoAwardTask">
                                <img :src="item.icon" alt="">
                                <p v-html="item.title"></p>
                                <button :style="taskInfo.subTasks.length>2?'':'width:calc(100% - 60px);left:30px;'">{{!item.done?item.btnName:'已完成'}}</button>
                            </template>
                            <template v-else>
                                <div>
                                    <p v-html="item.title"></p>
                                    <img :src="item.icon" alt="">
                                </div>
                                <div>{{ item.desc }}</div>
                                <button>{{!item.done?item.btnName:'已完成'}}</button>
                                <img class="xs-coin-icon" src="https://ppyos.xijiuyou.com/202303/xs-coin-icon.png" alt="">
                            </template>
                        </div>
                        <div class="welfare-content-list-lock" v-if="!taskInfo.taskInfoShow" :style="taskInfo.subTasks.length<3?'background: url(https://ppyos.xijiuyou.com/202303/mask-2-img.png) no-repeat;background-size: 100% 100%;':''">
                            <div v-html="newtimedays(taskInfo.taskInfoShowCountdown?taskInfo.taskInfoShowCountdown:0)">

                            </div>
                            <div>解锁任务</div>
                        </div>
                    </div>
                    <div class="welfare-content-button" :style="!taskInfo.taskAwarded?taskInfo.awardButtonEnabled?'':indexs>=2?'opacity: 0.4;width:300px;':'opacity: 0.4;':indexs>=2?'background:rgba(216, 216, 216, 1);box-shadow:none;width:300px;':'background:rgba(216, 216, 216, 1);box-shadow:none;'"  @click="btnlink(taskInfo)">{{ (taskInfo.taskAwarded?'已':'')+(taskInfo.awardButton===undefined?'任务已结束':taskInfo.awardButton) }}</div>
                    <div class="welfare-content-explain"><img src="https://ppyos.xijiuyou.com/202303/gt-icon.png" alt=""> 友情提示：完成任务并领奖后，会在 <span>0点</span> 刷新次日任务</div>
                </div>
            </div>
            <div class="myphone" v-if="phonelist.length > 0">
                <img  class="myphone-title" src="https://ppyos.xijiuyou.com/202306/extend-task-font-img.png" alt="">
                <div class="myphone-list" v-for="(item,index) in phonelist" :key="index">
                    <div>
                        <img :src="item.taskIcon" alt="">
                        <div>
                            <p>{{item.taskName}}</p>
                            <p>{{item.taskDesc}}</p>
                        </div>
                    </div>
                    <button :class="item.status===1?'btnlin':item.status===2?'btnlins':''" @click="golinklin(item)">{{item.status===1?'领取奖励':item.status===2?'已领取':item.btnName  }}</button>
                </div>
            </div>
            <div class="couponlist">
                <div class="couponlisttable">
                    <div :class="couponlistcheck === 0?'checkbtn':''" @click="couponlistbtn(0)">未使用</div>
                    <div :class="couponlistcheck === 1?'checkbtn':''" @click="couponlistbtn(1)">已使用</div>
                    <div :class="couponlistcheck === 2?'checkbtn':''" @click="couponlistbtn(2)">已过期</div>
                </div>
                <div class="couponlist-list"  v-for="(item,index) in couponlist_" :key="index"  :style="couponlistcheck == 0?'':'background:url(https://ppyos.xijiuyou.com/202203/card/gift-bg-img.png) no-repeat;background-size:100% 100%;'">
                    <div :style="couponlistcheck != 0?'color:rgba(106, 106, 106, 1);':''" v-html="item.desc"></div>
                    <div>
                        <p>{{item.title}}</p>
                        <p>{{ item.note }}</p>
                        <p>{{dayjstime(item.expiredTime)}}到期</p>
                    </div>
                    <button :style="couponlistcheck != 0?'background:rgba(0,0,0,0);':''" @click="golink(item.link)">{{couponlistcheck == 0?"去使用":''}}</button>
                    <img v-if="couponlistcheck == 1" class="listy" src="https://ppyos.xijiuyou.com/202203/card/used-tag-img.png" alt="" />
                    <img v-if="couponlistcheck == 2" class="listg" src="https://ppyos.xijiuyou.com/202203/card/expired-icon.png" alt="" />
                </div>
                <div class="jiangpnot" v-if="couponlist_.length<1">
                    <img src="https://ppyos.xijiuyou.com/202203/card/empyt-img.png" alt="" />
                    <p>还未获得卡券哦~</p>
                </div>
            </div>
            <!-- <img class="introduce-img" src="https://ppyos.xijiuyou.com/202303/limit-task-introduce-img.png" alt=""> -->
        </div>
        <div class="guize" @click="closeguize">规则</div>
        <div class="jiangp" @click="closejiangp">我的奖品</div>
        <xwpopup  :isshowpopup="guize" @close="closeguize()" :hiddenClose="false">
            <div class="guizes">
                <div> <img src="https://ppyos.xijiuyou.com/202303/jianbian-2.png" alt=""> 规则 <img src="https://ppyos.xijiuyou.com/202303/jianbian-1.png" alt=""></div>
                <p><span>1.</span>本活动自新用户注册登录后3个自然日内有效，注册当日计算为第1天，注册后第3日24点活动结束；</p>
                <p><span>2.</span>完成当日任务后，需要自己主动领取奖励；</p>
                <p><span>3.</span>在活动有效期内，如果有某天未登录没有做任务，可以跨天追赶。即第2天登录，可以连做第1天、第2天任务；第3天登录，可以连做第1-3天所有任务；</p>
                <p><span>4.</span>每日任务完成后，次日相同类型的任务将从零开始计算进度；</p>
                <p><span>5.</span>小游戏频道任务，PK赛、福利赛小游戏上传一次成绩即可，彩贝小游戏，参与游玩一次即可；高额赚频道任务，计算高额奖励和充值超返，不包含平台活动奖励、福利奖等特殊奖励；</p>
                <p><span>6.</span>本活动解释权归本平台所有</p>
            </div>
        </xwpopup>

        <xwpopup  :isshowpopup="weixin" @close="closeweixin()" :hiddenClose="false">
            <div class="weixin">
                <!-- https://ppyos.xijiuyou.com/202303/xs-gx-txt.png -->
                <img class="weixinhead" src="https://ppyos.xijiuyou.com/202203/card/draw-success-img.png" alt="" />
                <div class="weixinlist">
                    <!-- <template v-for="(item,index) in drawAwardlist">

                    </template> -->
                    <div v-for="(item,index) in drawAwardlist" :key="index">
                        <div class="redpack" v-if="item.code === 'prizeMoneySendToWechatService'">
                            <div><span>{{item.data.money}}</span>元微信红包</div>
                        </div>
                        <div  class="redpack1" v-else-if="item.code === 'prizeUserXjyCoinSendService'">
                            <span>{{item.coin}}</span><img src="https://ppyos.xijiuyou.com/202303/coin-icon.png" alt="">
                        </div>
                        <div  class="redpack2" v-else-if="item.code === 'prizeCardSendService'">
                            <div>
                                <p><span>¥</span>{{item.data.total}}</p>
                                <p>体验高额赚游戏奖励额外 <span>{{item.data.cardDesc}}</span></p>
                            </div>
                            <div>{{item.data.duration}}天收益加成卡<img src="https://ppyos.xijiuyou.com/202303/diamond-icon.png" alt=""></div>
                        </div>
                        <div class="weixinlist-cont" v-else>
                            <p class="weixinlist-cont-p" v-html="item.data.bonusDesc"></p>
                            <div>
                                <p>{{item.data.bonusTitle}}</p>
                                <p>{{ item.data.bonusNote }}</p>
                                <p>{{dayjstime(item.data.expiredAt)}}到期</p>
                            </div>
                        </div>
                    </div>

                </div>
                <button @click="closeweixin">收下了</button>
            </div>
        </xwpopup>
        <xwpopup  :isshowpopup="weixins" @close="closeweixins()" :hiddenClose="false">
            <div class="weixin">
                <!-- https://ppyos.xijiuyou.com/202303/xs-gx-txt.png -->
                <img class="weixinhead" src="https://ppyos.xijiuyou.com/202303/xs-gx-txt.png" alt="" />
                <div class="weixinlist">
                    <div  class="redpack1" v-if="prizeDesc.length>0">
                        <span>
                            +{{ prizeDesc[0].data.coin }}</span><img src="https://ppyos.xijiuyou.com/202303/coin-icon.png" alt="">
                    </div>
                </div>
                <button @click="closeweixins">收下了</button>
            </div>
        </xwpopup>
    </div>
</template>
<script>
import xwpopup from "../../components/Popupbox.vue";
import welfare from "../../api/welfare";
import { Toast } from "vant";
import dayjs from 'dayjs';
export default{
    components: {
        xwpopup,
    },
    data(){
        return{
            guize:false,
            countdown:0,
            taskGroups:[],
            taskInfo:{},
            prizeDesc:[],
            indexs:0,
            ttian:0,
            tshi:0,
            tfen:0,
            tianset:"",
            shiset:"",
            fenset:"",
            weixin:false,
            weixins:false,
            drawAwardlist:[],
            couponlistcheck:0,
            page:1,
            couponlist_:[],
            phonelist:[],
        }
    },
    mounted(){
        this.tasksinfo();
        this.extendTasks();
        this.couponlistbtn(this.couponlistcheck);
    },
    destroyed(){
        clearInterval(this.tianset);
        clearInterval(this.shiset);
        clearInterval(this.fenset);
    },
    methods:{
        golinklin(item){
            if(item.status === 0){
                window.location.href = item.link;
            }else if(item.status === 1){
                console.log(1)
                welfare.extendTaskAward({taskId:item.taskId,taskKey:item.taskKey}).then((res)=>{
                    console.log(res)
                    this.weixins = true;
                    this.prizeDesc = res.data;
                })
            }
        },
        extendTasks(){
            welfare.extendTasks().then(res=>{
                this.phonelist = res.data;
            })
        },
        couponlistbtn(index){
            this.couponlistcheck = index;
            this.couponlist_ = [];
            const data={
                status:index,
                page:this.page,
                pageSize:20
            }
            welfare.myBonus(data).then(res=>{
            this.couponlist_.push(...res.list);
            })
        },
        dayjstime(item){
            return dayjs(item).format("YYYY-MM-DD");
        },
        closeweixin(){
            this.weixin = !this.weixin;
            this.tasksinfo();
        },
        closeweixins(){
            this.weixins = !this.weixins;
            this.extendTasks();
        },
        btnlink(item){
            // console.log(item)
            // const that = this;
            if(item.taskAwarded){
                Toast("奖励已领取");
                return false;
            }
            if(item.awardButtonEnabled){
                welfare.drawAward(item.day).then(res=>{
                    console.log(res)
                    // Toast({
                    //     message: '领取成功',
                    //     onClose(){
                    //         that.tasksinfo();
                    //     }
                    // });
                    this.drawAwardlist = res.data;
                    this.weixin = true;
                })
            }else{
                Toast("您的任务还没有完成");
            }
        },
        golink(item,index){
            try {
                window.android.trackEvent(`lt_day_${this.indexs}_${index+1}`,"");
            } catch (error) {
                console.log(error)
            }
            window.location.href = item.link;
        },
        golist(item,index){
            if(!item.locked){
                this.taskInfo = item;
                this.indexs = index;
            }else{
                Toast("任务尚未解锁");
            }
        },
        tian(){
            this.ttian = parseInt(this.countdown/3600/24);
            this.tianset = setInterval(()=>{
                this.countdown--;
                this.ttian = parseInt(this.countdown/3600/24);
            },1000)
        },
        shi(){
            this.tshi = parseInt((this.countdown/3600)%24)>9?parseInt((this.countdown/3600)%24):"0"+parseInt((this.countdown/3600)%24)
            this.shiset =setInterval(()=>{
                this.tshi = parseInt((this.countdown/3600)%24)>9?parseInt((this.countdown/3600)%24):"0"+parseInt((this.countdown/3600)%24)
            },1000)
        },
        fen(){
            this.tfen = parseInt((this.countdown%3600/60))>9?parseInt((this.countdown%3600/60)):"0"+parseInt((this.countdown%3600/60))
             this.fenset =setInterval(()=>{
                this.tfen = parseInt((this.countdown%3600/60))>9?parseInt((this.countdown%3600/60)):"0"+parseInt((this.countdown%3600/60))
            },1000)
        },
        newtimedays(time){
            if(parseInt((time/3600)%24) >= 1){
                return `<span  style="width: 22px;
                                height: 22px;
                                background: #FFFFFF;
                                border-radius: 2px;
                                text-align: center;
                                line-height: 22px;
                                display: block;
                                margin: 0 8px;
                                color: rgba(255, 42, 42, 1);
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;">${parseInt((time/3600)%24)>9?parseInt((time/3600)%24):'0'+parseInt((time/3600)%24)}</span> 时 <span  style="width: 22px;
                                height: 22px;
                                background: #FFFFFF;
                                border-radius: 2px;
                                text-align: center;
                                line-height: 22px;
                                display: block;
                                margin: 0 8px;
                                color: rgba(255, 42, 42, 1);
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;">${parseInt((time%3600/60))>9?parseInt((time%3600/60)):'0'+parseInt((time%3600/60))}</span> 分后`
            }else{
                return `<span  style="width: 22px;
                                height: 22px;
                                background: #FFFFFF;
                                border-radius: 2px;
                                text-align: center;
                                line-height: 22px;
                                display: block;
                                margin: 0 8px;
                                color: rgba(255, 42, 42, 1);
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;">${parseInt((time%3600/60))>9?parseInt((time%3600/60)):'0'+parseInt((time%3600/60))}</span> 分后`;
            }
        },
        tasksinfo(){
            const that = this;
            welfare.tasksinfo().then(res=>{
                console.log(res)
                that.countdown = res.data.countdown;
                that.taskGroups = res.data.tasks;
                that.prizeDesc = res.data.prizeDesc;
                if(res.data.prizeDesc.length>0){
                    that.weixins = true;
                }else{
                    that.weixins = false;
                }
                this.tian();
                this.shi();
                this.fen();
                //this.$nextTick({})
                const locked =res.data.tasks.findIndex((item)=>item.locked);
                if(locked>0){
                    that.taskInfo = res.data.tasks[locked-1];
                    that.indexs = locked-1;
                }else{
                    that.taskInfo = res.data.tasks[res.data.tasks.length-1];
                    that.indexs = res.data.tasks.length-1;
                }
                console.log(that.taskInfo)
            })
        },
        closeguize(){
            this.guize = !this.guize;
        },
        closejiangp(){
            this.$router.push({
                name:"myprize"
            })
        },
    }
}
</script>
<style lang="less" scoped>
.welfare{
    width: 100vw;
    min-height: 100vh;
    background: #99D5FC;
    text-align: center;
    padding-bottom: 30px;
    .welfarehead{
        width: 100%;
        background: url(https://ppyos.xijiuyou.com/202303/limit-task-big-bg.png) no-repeat;
        background-size: 100%;
        .welfare-title{
            width: 100%;
            height: 30px;
            background: url(https://ppyos.xijiuyou.com/202303/hui-long-banner.png) no-repeat;
            background-size: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            span{
                margin: 0 8px;width: 22px;height: 22px;background: #FF5A78;border-radius: 2px;display: flex;justify-content: center;align-items: center;font-size: 14px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FFFFFF;
            }
        }
        .welfare-content{
            width: calc(100% - 24px);
            margin: 0 auto;
            margin-top: 240px;
            .welfare-content-table{
                display: flex;
                justify-content: space-between;
                div{
                    width: 32%;
                    height: 57px;
                    background: linear-gradient(360deg, #FFC84A 0%, #FFF7BB 100%);
                    border-radius: 15px 15px 0 0;
                    .tab-day{
                        width: 100%;
                        height: 42px;
                        margin-top: -8px;
                    }
                    div{
                        width: 100%;
                        img{
                            width: 14px;
                            height: 14px;
                            margin-right: 4px;
                        }
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: rgba(37, 37, 37, 0.4);
                        display: flex;
                        justify-content: center;
                        padding-top: 10px;
                    }
                }
            }
            .welfare-content-content{
                width: 100%;
                background: #FFFFFF;
                border-radius: 6px 6px 17px 17px;
                margin-top: -23px;
                position: relative;
                z-index: 1;
                padding-bottom: 24px;
                .welfare-content-content-title{
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    text-align: center;
                    padding-top: 16px;
                    span{
                        color: rgba(255, 42, 42, 1);
                    }
                }
                .welfare-content-button{
                    width: 240px;
                   display: inline-block;
                    background: linear-gradient(270deg, #FF5858 0%, #FF5FA0 100%);
                    box-shadow: inset 0px -6px 9px 0px rgba(218,0,37,0.3), inset 0px 2px 3px 0px rgba(255,255,255,0.5);
                    border-radius: 24px;
                    margin: 0 auto;
                    padding: 14px 0;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                }
                .welfare-content-explain{
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 16px;
                    span{
                        color: rgba(255, 42, 42, 1);
                    }
                    img{
                        width: 13px;
                        height: 13px;
                    }
                }
                .welfare-content-list{
                    width: calc(100% - 24px);
                    margin: 16px auto 31px;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    .welfare-content-caibei{
                        width:49%;
                        position: relative;
                        padding: 9px 13px;
                        box-sizing: border-box;
                        text-align: left;
                        div{
                            width: auto;
                            border:0;
                            display: flex;
                            text-align: left;
                            padding: 0;
                            img{
                                margin-left: 5px;
                                margin-top:-3px;
                            }
                        }
                        div:nth-child(2){
                            padding: 0;
                            font-size: 14px;
                            font-family: DINOffcPro-Medi, DINOffcPro;
                            font-weight: normal;
                            color: #444444;
                            display: flex;
                            align-items: center;
                            margin: 7px 0;
                            span{
                                font-size: 12px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #444444;
                            }
                        }
                        .xs-coin-icon{
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            width: 57px;
                            height: 39px;
                        }
                        button{
                            width: 70px;
                            position: relative;
                            margin-left: 0;
                            left: auto;
                            bottom: auto;
                        }
                    }
                    .welfare-content-list-lock{
                        position:absolute;
                        width: 100%;
                        height: 115px;
                        background: url("https://ppyos.xijiuyou.com/202303/mask-3-img.png") no-repeat;
                        background-size: 100% 100%;
                        border-radius: 10px;
                        backdrop-filter: blur(4px);
                        border: 0px;
                        padding-top: 25px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        div{
                            border:0;
                            background: none;
                            padding: 0;
                            width: 100%;
                        }
                        div:nth-child(1){
                            font-size: 13px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #252525;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        div:nth-child(2){
                            font-size: 14px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #444444;
                            margin-top: 6px;
                        }
                    }
                    div{
                        text-align: center;
                        width: calc(33% - 4px);
                        background: #FFF0F0;
                        border-radius: 10px;
                        border: 1px solid #FFCECE;
                        font-size: 12px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #CE0048;
                        padding-top: 7px;
                        position: relative;
                        padding-bottom: 20px;
                        img{
                            width: 22px;
                            height: 22px;
                        }
                        button{
                            width: calc(100% - 30px);
                            background: linear-gradient(270deg, #FF5858 0%, #FF5FA0 100%);
                            border-radius: 20px;
                            border:0px;
                            color: #ffffff;
                            padding: 5px 0;
                            position: absolute;
                            bottom: -11px;
                            left: 15px;
                        }
                    }
                }
            }
        }
    }
    .introduce-img{
        width: calc(100% - 24px);
        margin-top: 10px;
    }
}
.guize{
    position: absolute;
    right: 0;
    top: 88px;
    width: 27px;
    height: 44px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 8px 0px 0px 8px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: 20px;
    padding: 0 2px;
    display: flex;
    align-items: center;
}
.jiangp{
    position: absolute;
    right: 0;
    top: 147px;
    width: 27px;
    height: 70px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 8px 0px 0px 8px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: 16px;
    padding: 0 2px;
    display: flex;
    align-items: center;
}
.guizes{
    width: 305px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-top: 100px;
    padding: 24px 28px;
    max-height: 400px;
    overflow-x: auto;
    div{
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #444444;
        margin-bottom: 10px;

        img{
            width: 23px;
            height: 12px;
        }
    }
    p{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
        text-align: left;
        display: flex;
        line-height: 25px;
    }
}
.weixin{
    width: calc(100% - 80px);
    background: #ffffff;
    border-radius: 20px;
    margin-top: 120px;
    padding:2px 2px 20px 2px;
    button{
        width: 180px;
        height: 44px;
        background: linear-gradient(270deg, #FF5858 0%, #FF5FA0 100%);
        box-shadow: inset 0px -6px 9px 0px rgba(218,0,37,0.3), inset 0px 2px 3px 0px rgba(255,255,255,0.5);
        border-radius: 24px;
        border:0;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin-top:19px;
    }
    .weixinhead{
        width: 100%;
    }
    .weixinlist{
        width: 257px;
        text-align: center;
        margin: 7px auto 0;
        .redpack{
            background: url("https://ppyos.xijiuyou.com/202303/redpack-bg.png") no-repeat;
            background-size: 100%;
            width: 100%;
            height: 66px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFEDC7;
            span{
                font-size: 25px;
            }
        }
        .redpack1{
            background: url("https://ppyos.xijiuyou.com/202303/coin-bg-2.png") no-repeat;
            background-size: 100%;
            width: 100%;
            height: 66px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                font-size: 26px;
                font-family: DINOffcPro-Black, DINOffcPro;
                font-weight: 900;
                line-height: 34px;
                background: linear-gradient(180deg, #C447FF 0%, #6222FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            img{
                width: 84px;
            }
        }
        .redpack2{
            background: url("https://ppyos.xijiuyou.com/202303/coin-bg-1.png") no-repeat;
            background-size: 100%;
            width: 100%;
            height: 66px;
            text-align: left;
            position: relative;
            padding-top: 4px ;
            padding-left: 16px;
            div:nth-child(1){
                p:nth-child(1){
                    font-size: 26px;
                    font-family: DINOffcPro-Black, DINOffcPro;
                    font-weight: 900;
                    color: #2C69CD;
                    text-shadow: 0px 2px 0px rgba(255,255,255,0.7), 0px 1px 0px rgba(0,0,0,0.2);
                    span{
                        font-size: 14px;
                    }
                }
                p:nth-child(2){
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2C69CD;
                    span{
                        font-size: 16px;
                        font-family: DINOffcPro-Black, DINOffcPro;
                        font-weight: 900;
                    }
                }
            }
            div:nth-child(2){
                position: absolute;
                right: 0;
                top: 0;
                background: rgba(65, 129, 213, 0.7);
                border-radius: 0px 10px 0px 11px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                padding: 0 6px;
                img{
                    width: 16px;
                    height: 16px;
                    margin-left: 4px;
                }
            }
        }
        .weixinlist-cont{
            padding-left: 20px;
            background: url("https://ppyos.xijiuyou.com/202203/card/gift-pink-line-bg.png") no-repeat;
            background-size: 100%;
            display: flex;
            align-items: center;
            height: 66px;
            margin-top: 7px;
            .weixinlist-cont-p{
                width: 50px;
            }
            p{
                margin-right: 38px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF2A2A;
                .money{
                    font-size: 25px;
                    font-family: DINOffcPro-Bold, DINOffcPro;
                    font-weight: bold;
                    color: #FF2A2A;
                }
            }
            div{
                padding-left: 0px;
                display: block;
                background:rgba(0 , 0 , 0 , 0);
                text-align: left;
                p:nth-child(1){
                    font-size: 13px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #444444;
                }
                p{
                    margin:0;
                    font-size: 10px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #888888;
                }
            }
        }
    }
}
.couponlist{
    width: calc(100% - 24px);
    margin: 10px auto 0;
    background: #FFFFFF;
    border-radius: 17px;
    padding: 12px;
    box-sizing: border-box;
    .couponlisttable{
        width: calc(100% - 54px);
        height: 28px;
        background: #F6F6F6;
        border-radius: 14px;
        display: flex;
        line-height: 28px;
        margin: 0 auto;
        .checkbtn{
            background: linear-gradient(90deg, #FF8600 0%, #FF4700 100%);
            color: #ffffff;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
        div{
            width: 100%;
            height: 100%;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            border-radius: 14px;
            height: 100%;
        }
    }
    .couponlist-list{
        width: 100%;
        height: 82px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px 0 20px;
        background: url("https://ppyos.xijiuyou.com/202306/bound/pink-bg.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 12px;
        position: relative;
        div:nth-child(1){
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF2A2A;
            span{
                font-size: 25px;
                font-family: DINOffcPro-Bold, DINOffcPro;
                font-weight: bold;
                color: #FF2A2A;
            }
        }
        div:nth-child(2){
            text-align: left;
            p{
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #888888;
            }
            p:nth-child(2){
                margin: 3px 0;
            }
            p:nth-child(1){
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #444444;
            }
        }
        button{
            background: linear-gradient(270deg, #FF9E9E 0%, #FF6767 100%);
            border-radius: 13px;
            border:0;
            width: 54px;
            height: 24px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
        .listy{
            position: absolute;
            width: 42px;
            right: 0;
            bottom: 0;
        }
        .listg{
            position: absolute;
            width: 82px;
            right: 0;
            bottom: 7px;
        }
    }
}
.jiangpnot{
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
        img{
            margin-top: 30px;
            width: 134px;
        }
        p{
            margin-top: 15px;
        }
    }
    .myphone{
        width: calc(100% - 24px);
        margin: 10px auto 0;
        background: #FFFFFF;
        border-radius: 17px;
        padding: 14px 14px 14px;
        box-sizing: border-box;
        .myphone-title{
            width: 72px;
            height: 19px;
            margin-bottom: 10px;
        }
        .myphone-list{
            background: #FFF0F0;
            border-radius: 10px;
            width: 100%;
            padding: 16px 14px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div:nth-child(1){
                display: flex;
                align-items: center;
                img{
                    width: 42px;
                    height: 42px;
                    margin-right: 11px;
                }
                p{
                    font-size: 14px;
                    font-family: DINOffcPro-Medi, DINOffcPro;
                    font-weight: normal;
                    color: #444444; 
                }
                p:nth-child(1){
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #CE0048;
                }
            }
            button{
                background: linear-gradient(270deg, #FF5858 0%, #FF5FA0 100%);
                box-shadow: inset 0px 1px 3px 0px rgba(255,255,255,0.5), inset 0px -1px 4px 0px rgba(218,0,37,0.5);
                border-radius: 17px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                border:0;
                padding: 8px 22px;
            }
            .btnlin{
                background: linear-gradient(270deg, #AD49FF 0%, #7093FF 100%);
                box-shadow: inset 0px 1px 3px 0px rgba(255,255,255,0.5), inset 0px -1px 4px 0px rgba(119,0,218,0.5);
            }
            .btnlins{
                opacity: 0.3;
            }
        }
    }
</style>
<style>
.couponlist-list .money{
    font-size: 25px;
    font-family: DINOffcPro-Bold, DINOffcPro;
    font-weight: bold;
}
</style>