import axios from "axios";
import qs from'qs';
const welfare = {
    tasksinfo(){
    return axios.get('/api/ppy/usercenter/limitTask/info')
  },
  drawAward(day){
    return axios.post('/api/ppy/usercenter/limitTask/takeTaskAward',qs.stringify({day}))
  },
  prizeList(data){
    return axios.get('/api/ppy/usercenter/limitTask/prizeList',{
      params:data
    })
  },
  myBonus(data){
    return axios.get('/api/ppy/usercenter/bonus/myBonus',{
      params:data
    })
  },
  extendTasks(){
    return axios.get('/api/ppy/usercenter/limitTask/extendTasks')
  },
  extendTaskAward(data){
    return axios.post('/api/ppy/usercenter/limitTask/extendTaskAward',qs.stringify(data))
  },
}

export default welfare;
